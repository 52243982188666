<template>
<div class='page-container'>
  <Product :productId="productId" :inWindow="true"/>
</div>
</template>

<script>
import Product from '../components/shared/Product.vue';

export default {
  name: 'Krpano',
  components: {
    Product
  },
  data() {
    return {
      productId: this.$route.params.product_id
    };
  },
  mounted() {
  },
  methods: {
  
  }
};
</script>

<style scoped>
  body, html {
    height: 100%;
  }

  .page-container{
    margin: 0px;
    width: 100%;
    height: calc(100vh - 124px);
    padding-left: 0px !important;
    padding-right: 0px !important;
    position: relative;
    z-index: 0;
  }

  /* .popup-container{
    position: fixed;
    z-index: 150;
    width: 100%;
    height: 100vh;
    margin-top: -70px;
  } */

  /* #### Mobile Phones Portrait #### */
  @media screen and (max-device-width: 480px) and (orientation: portrait) {
    .inbox-container {
      margin: 30% auto;
    }
  }

  /* #### Mobile Phones Landscape #### */
  @media screen and (max-device-width: 640px) and (orientation: landscape) {
    .inbox-container {
      margin: 5% auto;
      width: 80%;
    }
  }

</style>
